import { atom } from 'jotai';

import { ProjectIdColor } from '@/types/ProjectIdColor';
import { TimeSheetPayPeriod } from '@/types/TimeSheetPayPeriod';
import { getCurrentRequestedPayPeriod, RequestedPayPeriod } from '@/utility/dateUtils.ts';

export const calendarEventsSubmittedAtom = atom<boolean>(false);
export const globalDayCardStateAtom = atom<'open' | 'closed' | null>('open');
export const hideEmptyDaysAtom = atom<boolean>(false);
export const isDataLoadingAtom = atom<boolean>(false);
export const payPeriodAtom = atom<TimeSheetPayPeriod | undefined>(undefined);
export const payPeriodHasTimeEntriesAtom = atom<boolean>(false);
export const projectIdColorsAtom = atom<ProjectIdColor[] | undefined>(undefined);
export const requestedPayPeriodAtom = atom<RequestedPayPeriod>(getCurrentRequestedPayPeriod());
export const someTimeEntriesAreOpenAtom = atom<boolean>(false);
export const someTimeEntriesAreSubmittedAtom = atom<boolean>(false);
export const totalHoursPerDayAtom = atom<Map<string, number>>(new Map());
export const userIsW2Atom = atom<boolean>(true);
